@import './config.less';
@import 'loader.less';
@import 'tabs.less';
@import 'layout.less';
@import 'tooltip.less';
@import 'redactorjs.less';
@import '../../../../node_modules/flatpickr/dist/flatpickr.min.css';

html, body, app-root {
    height: 100%;
}

body {
    font-family: @defaultFont;
    color: var(--font);
    font-size: @defaultFontSize;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: var(--body-background);
	-webkit-tap-highlight-color: transparent;
}

.hover:hover {
    color: var(--primary);
}

.bold {
    font-weight: 700;
}

.hidden {
    visibility: hidden;
}

.hide {
    display: none !important;
}

.clear {
    clear: both;
}

.pointer {
    cursor: pointer;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.display-contents {
    display: contents;
}

/*
*	Border box
*/
*,
*:not(.fa):before,
*:not(.fa):after {
    box-sizing: border-box;
}

/* BASIC STYLING FOR THE INPUT FIELDS =============================== */

.form-group {
    position: relative;
    display: inline-block;
    width: 100%;
}

input {
    font-size: @fontSizeMedium;
    padding: 4px 0;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--primary);
    font-family: @defaultFont;
    background: @inputDefaultBackground;
}

input:focus {
    outline: none;
    border-bottom: 1px solid var(--primary);
    transition: 0.2s ease all;
}

*:focus {
    outline: none;
}

.selector {
    select {
        appearance: none;
        text-indent: 1px;
        position: relative;
        background: #ffffff;
        z-index: 1;
        cursor: pointer;
        box-shadow: none;
        padding: 9px 12px;
        border: @defaultBorder;
        display: table-cell;
        height: 47px;
        width: 75px;
        font-size: 18px;
        border-radius: 0;
    }

    .icon {
        pointer-events: none;
        position: relative;
        font-size: 14px;
        z-index: 40;

        i {
            position: absolute;
            right: 15px;
            top: -30px;
        }
    }
}

.dark-button {
    background-color: transparent;
    height: 43px;
    color: var(--font);
    border: 2px solid var(--black);
    font-weight: 700;
    padding: 0 20px;

    &:hover:not(:active):not(:disabled) {
        cursor: pointer;
        background-color: var(--black);
        color: var(--white);
    }
}

@media (max-width: @md) {
	.portal-menu > .options {
		justify-content: center;

		dashboard-box {
			width: 100%;
		}
	}
}
