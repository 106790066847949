/* You can add global styles to this file, and also import other style files */
@import '../node_modules/flatpickr/dist/flatpickr.min.css';
:root {
  --primary: #07243e;
  --primary-dark: #07243e;
  --primary-light: #0a2f50;
  --primary-light-dark: #0a2f50;
  --primary-lighter: #0a2f50;
  --primary-lighter-dark: #0a2f50;
  --secondary: #0e6973;
  --secondary-dark: #0e6973;
  --secondary-light: #19818d;
  --secondary-light-dark: #19818d;
  --secondary-lighter: #582c83;
  --secondary-lighter-dark: #582c83;
  --neutral1: #f7f7f7;
  --neutral1-dark: #454545;
  --neutral2: #f2f2f2;
  --neutral2-dark: #585858;
  --neutral3: #c8d1d1;
  --neutral3-dark: #717171;
  --neutral4: #979d9f;
  --neutral4-dark: #909090;
  --neutral5: #4d4d4d;
  --neutral5-dark: #9e9e9e;
  --black: #191919;
  --black-dark: #fff;
  --white: #fff;
  --white-dark: #191919;
  --background: var(--white);
  --background-dark: var(--white);
  --body-background: #f0f1f2;
  --body-background-dark: #2a2a2a;
  --font: #272727;
  --font-dark: #fff;
  --light-font: #7d7d7d;
  --light-font-dark: #7d7d7d;
  --disabled-font: #bac3c8;
  --disabled-font-dark: #bac3c8;
  --border: #d2d4d6;
  --border-dark: #d2d4d6;
  --error: #f6554a;
  --error-dark: #f6554a;
  --error-light: #fdeceb;
  --error-light-dark: #fdeceb;
  --success: #65bb6a;
  --success-dark: #65bb6a;
  --success-light: #edf7ee;
  --success-light-dark: #edf7ee;
  --alert: #fea228;
  --alert-dark: #fea228;
  --alert-light: #fff4e5;
  --alert-light-dark: #fff4e5;
  --notification: #41b6e6;
  --notification-dark: #41b6e6;
  --notification-light: #e9f4fd;
  --notification-light-dark: #e9f4fd;
  --nav-top-height: 100px;
  --footer-bottom-height: 72px;
  --offset-height: calc(var(--nav-top-height) + var(--footer-bottom-height));
}
@media (max-width: 800px) {
  :root {
    --nav-top-height: 60px;
    --footer-bottom-height: 120px;
  }
}
.loader-wrapper > div {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--primary);
  animation: spin 2s linear infinite;
  z-index: 1001;
}
.loader-wrapper > div:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--primary);
  animation: spin 3s linear infinite;
}
.loader-wrapper > div:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: var(--primary);
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  transform: translateX(0);
  background-color: var(--body-background);
}
.tabs {
  position: relative;
  display: flex;
  gap: 14px;
  background-color: var(--white);
  padding-top: 12px;
  padding-left: 40px;
}
@media (max-width: 1023px) {
  .tabs {
    gap: 12px;
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .tabs {
    gap: 0;
    justify-content: stretch;
    padding-top: 8px;
    padding-left: 0;
  }
}
.tabs > div {
  padding: 24px;
}
.tabs > div i {
  margin-right: 10px;
}
@media (max-width: 1023px) {
  .tabs > div {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .tabs > div {
    width: 100%;
    text-align: center;
    padding: 14px;
  }
  .tabs > div i {
    margin-right: 6px;
  }
}
@media (max-width: 639px) {
  .tabs > div {
    padding: 14px 12px;
  }
}
@media (max-width: 419px) {
  .tabs > div {
    padding: 14px 10px;
  }
  .tabs > div i {
    margin-right: 4px;
  }
}
.tabs .active {
  background-color: var(--body-background);
  font-weight: 500;
}
sToggle > div,
sRadio > div,
sCheckbox > div {
  min-width: 24px;
}
sToggle label,
sRadio label,
sCheckbox label {
  cursor: pointer;
}
sCheckbox,
sRadio,
sToggle,
sInput,
sTextarea,
sSelect {
  display: inline-block;
  width: 100%;
}
sButton {
  display: inline-block;
}
/* tooltip */
[tooltip] {
  position: relative;
  cursor: pointer;
  border-bottom: dotted 1px;
}
[tooltip]:after {
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.18s;
  font-weight: 400 !important;
  font-style: normal !important;
  text-shadow: none !important;
  font-size: 12px !important;
  background: rgba(17, 17, 17, 0.9);
  border-radius: 4px;
  color: #fff;
  content: attr(tooltip);
  padding: 0.5em 1em;
  position: absolute;
  white-space: nowrap;
  z-index: 10;
}
[tooltip]:before {
  background: no-repeat url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.18s;
  content: '';
  position: absolute;
  z-index: 10;
}
[tooltip]:hover:before,
[tooltip]:hover:after {
  opacity: 1;
  pointer-events: auto;
}
[tooltip]:after {
  left: 50%;
  margin-top: 11px;
  top: 100%;
  transform: translate(-50%, -10px);
}
[tooltip]:before {
  background: no-repeat url('data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E');
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  left: 50%;
  margin-top: 5px;
  top: 100%;
  transform: translate(-50%, -10px);
}
[tooltip]:hover:after {
  transform: translate(-50%, 0);
}
[tooltip]:hover:before {
  transform: translate(-50%, 0);
}
[tooltip]:after {
  white-space: normal;
  width: 200px;
}
.redactor-clips-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.redactor-clips-list li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
}
.redactor-clips-list span {
  white-space: nowrap;
  background: var(--primary);
  color: #fff;
  display: inline-block;
  padding: 6px 8px;
  line-height: 1;
  border-radius: 4px;
  cursor: pointer;
}
.redactor-styles {
  color: #444950 !important;
}
.redactor-styles a[rel*='button'] {
  border-radius: 2px;
  background: transparent;
  color: var(--primary) !important;
  text-decoration: none !important;
  display: inline-block;
  padding: 5px 10px;
  border: solid 1px var(--primary);
}
.redactor-dropdown-format .redactor-dropdown-item-h1 {
  font-size: 32px !important;
}
.redactor-dropdown-format .redactor-dropdown-item-h2 {
  font-size: 28px !important;
}
.redactor-dropdown-format .redactor-dropdown-item-h3 {
  font-size: 32px !important;
}
.redactor-dropdown-format .redactor-dropdown-item-h4 {
  font-size: 24px !important;
}
.redactor-box.redactor-styles-on {
  background: #ffffff !important;
}
html,
body,
app-root {
  height: 100%;
}
body {
  font-family: 'Roboto', sans-serif;
  color: var(--font);
  font-size: 14px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--body-background);
  -webkit-tap-highlight-color: transparent;
}
.hover:hover {
  color: var(--primary);
}
.bold {
  font-weight: 700;
}
.hidden {
  visibility: hidden;
}
.hide {
  display: none !important;
}
.clear {
  clear: both;
}
.pointer {
  cursor: pointer;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.display-contents {
  display: contents;
}
/*
*	Border box
*/
*,
*:not(.fa):before,
*:not(.fa):after {
  box-sizing: border-box;
}
/* BASIC STYLING FOR THE INPUT FIELDS =============================== */
.form-group {
  position: relative;
  display: inline-block;
  width: 100%;
}
input {
  font-size: 18px;
  padding: 4px 0;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--primary);
  font-family: 'Roboto', sans-serif;
  background: var(--white);
}
input:focus {
  outline: none;
  border-bottom: 1px solid var(--primary);
  transition: 0.2s ease all;
}
*:focus {
  outline: none;
}
.selector select {
  appearance: none;
  text-indent: 1px;
  position: relative;
  background: #ffffff;
  z-index: 1;
  cursor: pointer;
  box-shadow: none;
  padding: 9px 12px;
  border: 1px solid var(--border);
  display: table-cell;
  height: 47px;
  width: 75px;
  font-size: 18px;
  border-radius: 0;
}
.selector .icon {
  pointer-events: none;
  position: relative;
  font-size: 14px;
  z-index: 40;
}
.selector .icon i {
  position: absolute;
  right: 15px;
  top: -30px;
}
.dark-button {
  background-color: transparent;
  height: 43px;
  color: var(--font);
  border: 2px solid var(--black);
  font-weight: 700;
  padding: 0 20px;
}
.dark-button:hover:not(:active):not(:disabled) {
  cursor: pointer;
  background-color: var(--black);
  color: var(--white);
}
@media (max-width: 767px) {
  .portal-menu > .options {
    justify-content: center;
  }
  .portal-menu > .options dashboard-box {
    width: 100%;
  }
}
